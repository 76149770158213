.center{
    text-align: center;
    margin-bottom: 30px;
    margin-top:auto;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.marquee {
    position: relative;
    overflow: hidden;
    --offset: 20vw;
    --move-initial: calc(-35% + var(--offset));
    --move-final: calc(-10% + var(--offset));
}


.marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 10, 0);
    animation: marquee 3s linear infinite;
}

.marquee:hover .marquee__inner {
    animation-play-state: running;
}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}