.fa-quote-left:before {
    content: "";
}
.fa-star:before {
    content: "";
}

.whatsapp_float {
          position: fixed;
          width: 60px;
          height: 60px;
          bottom: 5px;
          left: 4px;
          background-color: #05411b;
          color: #FFF;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          box-shadow: 2px 2px 3px #999;
          z-index: 100;
      }

      .whatsapp-icon {
          margin-top: 16px;
      }

      /* for mobile */
      @media screen and (max-width: 767px) {
          .whatsapp-icon {
              margin-top: 10px;
          }

          .whatsapp_float {
              width: 40px;
              height: 40px;
              bottom: 20px;
              right: 10px;
              font-size: 22px;
          }
      }