.testimonial__content h2 {
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  #c {
   margin-left:2.5px;
   text-align: center !important;
   align-items: center;
   justify-content: center;
   }
}
