.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  padding-top: 20px;
}
.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
}

.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}

.slide-effect {
  position: relative;
  overflow: hidden;
  display: flex;
  margin-top:5px;
}

.text, .slideDown, .slideUp {
  position: relative;
  opacity:0;
}

.text {
  top:40px;
  font-weight: bold;
  animation: slideUp ease .4s forwards;
}

.slideDown {
  top:-40px;
  margin-left: 5px;
  font-weight: bold;
  animation: slideDown ease .4s forwards .6s;
}

.slideUp {
  top:40px;
  margin-left: 5px;
  font-weight: bold;
  animation: slideUp ease .5s forwards 1.2s;
}

@keyframes slideUp {
  0% {transform: translateY(0);}
  100% {transform: translateY(-40px);opacity:1;}
}

@keyframes slideDown {
  0% {transform: translateY(0);}
  100% {transform: translateY(40px);opacity:1;}
}

@media only screen and (max-width: 600px) {
  .slide-effect, .text, .slideDown, .slideUp {
    font-size: 15px;
  }
}